import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useEffect, useState } from 'react';
import { getEmployeeShortsDropdown } from 'src/apis/master_data/employee.api';
import type { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  departmentId?: number;
  size?: SizeType;
};

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
}

interface OptionGroupType {
  label: React.ReactNode;
  departmentId?: number | undefined;
  options?: Omit<OptionType, 'children'>[];
}

export default function EmployeeGroupSelect({
  value,
  style,
  size,
  allowClear = true,
  onChange,
  departmentId,
  placeholder,
  disabled,
  returnValueType = 'object'
}: Props) {
  const [optionsGroup, setOptionsGroup] = useState<OptionGroupType[]>([]);
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeeShortsDropdown', departmentId],
    queryFn: () => getEmployeeShortsDropdown(departmentId),
    staleTime: 60 * 1000
  });

  // const newValue = employeesDropdownQuery.data?.data.find((item) => String(item.id) === String(value));

  useEffect(() => {
    if (employeesDropdownQuery.data?.data) {
      const newOptionsGroup: OptionGroupType[] = [];
      employeesDropdownQuery.data?.data.forEach((item) => {
        const index = newOptionsGroup.findIndex((optionGroup) => optionGroup.label === item.department?.name);

        if (index === -1) {
          newOptionsGroup.push({
            label: item.department?.name,
            options: [
              {
                label: `${item.code} - ${item.fullName}`,
                value:
                  returnValueType === 'object'
                    ? JSON.stringify(item)
                    : returnValueType === 'id'
                    ? String(item.id)
                    : item[returnValueType]
              }
            ]
          });
        } else {
          newOptionsGroup[index].options?.push({
            label: `${item.code} - ${item.fullName}`,
            value:
              returnValueType === 'object'
                ? JSON.stringify(item)
                : returnValueType === 'id'
                ? String(item.id)
                : item[returnValueType]
          });
        }
      });

      setOptionsGroup(newOptionsGroup);
    }
  }, [employeesDropdownQuery.data?.data, returnValueType]);

  return (
    <Select
      disabled={disabled}
      size={size}
      allowClear={allowClear}
      value={employeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={optionsGroup}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownQuery.isFetching}
    />
  );
}
